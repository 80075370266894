#ContactSection {
    display: flex;          /* Enable flexbox */
    flex-direction: column; /* Stack children vertically */
    align-items: center;   
    min-height: 80vh;      
    padding:0;
    padding-top: 200px;            
    color: white;
    font-family: "Roboto", sans-serif;
}

.contact-input {
    background-color: #323232 !important;
    color: white !important;
}

.contact-input input {
    color: white !important; /* Input text color */
  }
  
  .contact-input label {
    color: white !important; /* Label color */
  }
  
  .contact-input .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: none !important; /* Border color */
  }
  
  .contact-input .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: white !important; /* Border color on hover */
  }
  
  .contact-input .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: white !important; /* Border color on focus */
  }
  
@media screen and (max-width: 1200px) {
    #ContactSection {
        margin: 0 10vw !important;
    }
  }