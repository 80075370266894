/* .slick-dots button {
    color:white !important; 
    background-color: gray !important;
    border-radius: 50%;
} */

.slick-dots li button::before {
    color:white !important; 
}


  