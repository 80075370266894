#HomeSection {
  align-items: center !important;
}

.intro-container {
  justify-content: flex-end;
  align-items: center;
}

.terminal-container {
  justify-content: flex-start;
  align-items: center;
}

.intro {
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
  max-width: 80vw;
}

.hello {
  font-weight: 300;
  font-size: 2rem;
}

.aboutText {
  font-weight: 500;
  font-size: 3rem;
}

.aboutName {
  color: orange;
}

.aboutDescription {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 1rem;
}

.resume-btn {
  background-color: #373737 !important;
  color: white !important;
  border-color: none !important;
}

.icon-spacing {
  margin-right: 8px; /* Adjust the spacing as needed */
}

@media screen and (max-width: 1200px) {
    /* #HomeSection {
      padding-top: 150px !important;
    } */
  
    .intro-container {
      justify-content: center;
      transform: translateX(0%);
      max-height: 10vh;
      align-items: flex-end;
    }
    .terminal-container {
      justify-content: center;
      transform: translateX(0%);
      justify-content: center !important; /* This will center the child horizontally */
      align-items: flex-start;
    }
  }

@media screen and (max-width: 1200px) {
  #HomeSection {
    padding-top: 150px !important;
  }

  .intro-container {
    justify-content: center;
    transform: translateX(0%);
    /* align-items: flex-end; */
    padding-bottom: min(150px, 50%);
  }

  .hello {
    font-size: 1.5rem;
  }

  .aboutText {
    font-weight: 500;
    font-size: 2rem;
  }
    
  .terminal-container {
    justify-content: center;
    transform: translateX(0%);
    padding-top: 0 !important;
  }
}

@media screen and (max-width: 400px) {
    /* #HomeSection {
        padding-top: 150px !important;
      } */
  }
  