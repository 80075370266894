.card-container {
  display: flex !important;
  justify-content: center !important; /* This will center the child horizontally */
  align-items: center !important; /* This will center the child vertically if needed */
  width: 100%; /* Ensure the div takes the full width */
  /* Remove the inline display: inline-block; since flex is used */
}

.card {
  max-width: 50vw;
  margin-top: 40px;
  border: 1px solid rgb(60, 60, 60);
  margin-bottom: 30px;
}

.card-media {
  height: max(400px, 50vh);
  object-fit: cover;
}

.card-content {
  background-color: #323232;
  color: white;
}

.project-description {
  text-align: start;
}

.card-actions {
  background-color: #323232;
}

.technology-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* This will add some space between the items */
  justify-content: flex-start; /* Align items to the start of the container */
}

@media screen and (max-width: 1200px) {
  .card {
    max-width: 70vw;
    margin-bottom: 40px;
  }
  .card-media {
    height: min(500px, 25vh);
  }
}
